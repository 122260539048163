<template>
    <q-dialog 
      ref="dialogCSFx"
      v-model="modalForm.show"
      content-class="cannaFormModal"
      transition-show="scale" 
      transition-hide="scale"
      :persistent="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-width="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-height="(is_Mobile|| $q.screen.lt.sm)?true:false"
      :no-esc-dismiss="(is_Mobile|| $q.screen.lt.sm)?false: ((modalForm.processing) ? true : false)"
      :no-backdrop-dismiss="(is_Mobile|| $q.screen.lt.sm)?false:true"
      @show="setShownDialog"
      @escape-key="doCloseDialog(true)"
    >
      <q-card class="canna_Formx ">
        <!-- encabezado -->
        <q-card-section horizontal class="q-card q-pa-md flex justify-between column noselect">
          <div class="closeButton">
            <cxIcon
              iconType="ui_close"
              size="24px"
              class=""
              :cls="'icon_close_svg'"
              :state="(modalForm.processing === false) ? 0 : 2"
              :hasMouseOver="true"
              :hasAction="true"
              :allowDisabled="true"
              :allowClick="true"
              @iconClick="doCloseDialog(true)"
            />
          </div>
          <div class="form_title">

            <span>Resultados de la búsqueda</span>
            <template v-if="searchRecords.length>0">
              <div class="subtitleAlt">
                Estos son los resultados que generó la búsqueda con el texto 
                <span style="font-weight: 500;">{{searchTextColonia}}</span>
              </div>
            </template>

          </div>
        </q-card-section>

        <q-separator />
        <!-- formulario -->
        <template v-if="searchRecords.length > 0">

          <q-card-section class="canna_FormxContents scroll" 
            style="min-height: calc(100% - 135px); padding: 0px; position: relative; overflow: hidden; max-height: calc(100% - 135px);"
            :style="(is_Mobile|| $q.screen.lt.sm)?
              'min-height: calc(100% - 135px); padding: 0px; position: relative; overflow: hidden; max-height: calc(100% - 135px);' :
                'min-height: calc(75vh - 135px) !important; max-height: calc(100% - 135px); position: relative; overflow: hidden;'"
          >
            <!-- style="min-height: calc(100% - 135px); padding: 0px; position: relative; overflow: hidden; max-height: calc(100% - 135px);" -->
            <!-- Contenido del formulario -->
            <div style="width: 100%; min-height: 100%; position: absolute; max-height: 100%;display: flex;flex-direction: column;"> 
              <div
                style="height: calc(-69px + 100%) !important; overflow: auto; position: relative; padding: 0px;"
              >
                <!-- :style="(is_Mobile|| $q.screen.lt.sm)?'height: calc(76vh - 78px) !important;':''" -->
                <div class="coloniaSearch_Container" style="padding: 0px 14px;">
                  <template v-for="(record, index) in searchRecords">
                    <div class="coloniaSX_ItemRecord action_item noselect" :key="'crs_' + index + '_' + record.id"
                      :class="{
                        'coloniaSx_selected': (selectedId === record.id ) ? true : false 
                      }"
                      @click.stop="doSelectColonia(record)"
                    >
                      <div>
                        <div class="title">
                          {{record.d_asenta}}
                        </div>
                        <div class="textoInfo">
                          {{ 
                            record.d_codigo + ((record.D_mnpio !== '') ? ', ' + record.D_mnpio : '') + ((record.d_estado !== '') ? ', ' + record.d_estado : '')
                          }}
                        </div>
                      </div>
                    </div>
                  </template>

                </div>
              </div>
              
              <div class="buttonZone" style="margin-top: 18px !important; margin-bottom: 18px !important; padding: 0px 24px;">
                <template v-if="(selectedId === null)">
                  <q-btn 
                    type="button" :label="'Cerrar'" unelevated 
                    class="buttonSave_SE" 
                    color="app_mainBtn"
                    @click.stop="doCloseDialog(true)"
                  />
                </template>
                <template v-else>
                  <q-btn 
                    type="button" :label="'Seleccionar colonia'" unelevated 
                    class="buttonSave_SE" 
                    color="app_mainBtn"
                    @click.stop="onSubmit()" 
                  />
                </template>

              </div>

            </div>
          </q-card-section>

        </template>

        <template v-if="searchRecords.length <= 0">

          <q-card-section  style="max-height: 85vh" class="canna_FormxContents scroll">
            <div class="formTitle_ColoniaSearch noselect">
              No hay coincidencias con tu búsqueda
            </div>
            <div class="subtitleAlt noselect">
              <div style="text-align: center;">
                Revisa que hayas escrito correctamente las palabras o intenta con otros criterios de búsqueda.
              </div>
            </div>
            <div style="display: flex;justify-content: center;">
              <cxBackground :bgType="'planes_nosearch'" :size="currentSizeNodata" />
            </div>
            <div class="buttonZone" style="margin-top: 0px !important;">
              <q-btn 
                unelevated :label="'Cerrar'" 
                type="button" class="buttonSave_SE spacerRowTop24" color="app_mainBtn" 
                @click.stop="doCloseDialog(true)" 
              />
            </div>
          </q-card-section>

        </template>

        <!-- Loader de forma -->
        <!-- 
        <q-inner-loading :showing="modalForm.processing">
          <q-spinner-orbit size="100px" color="indigo" />
        </q-inner-loading>
        -->

      </q-card>
    </q-dialog>
</template>

<script>
import myplatform_Mixin from '../../../mixins/platformMix.js'

export default {
  name: 'modalColoniaSearch_form',
  props: {
    actionForm: { // verify, add, remove, mainchange
      type: String,
      required: true,
      default: 'results'
    },
    searchTextColonia:{
      type: String,
      required: true,
      default: ''
    },
    searchRecords: { 
      type: Array, 
      required: true, 
      default: function(){ 
        return []
      }
    }
  },
  components: {
    cxBackground: require('components/appBackgrounds').default, 
    cxIcon: require('components/appIcons.vue').default
  },
  mixins: [
    myplatform_Mixin
  ],
  data () {
    return {
      version: '1.0.1',
      // -----------------
      initFormFlag: false,
      processingData:false, 
      currentSizeNodata: '300px',
      selectedItem: null,
      selectedId: null,
      modalForm: {
        show: false,
        processing: false
      },
    }
  },
  computed: {
    show: {
      get: function () {
        return this.$parent.frm_coloniaModalShow
      },
      set: function (value) {
        this.$parent.frm_coloniaModalShow = value
      }
    },
    parentX () {
      return this.$parent
    },
    mainErrorsTest () {
      return {}
    },
    mainErrors () {
      var countx = 0
      return countx
    }
  },
  watch: {
    initFormFlag: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.show': function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        this.selectedItem = null 
        this.selectedId = null 
        //this.$refs.dialogCSFx.show()
      } else { 
        this.selectedItem = null 
        this.selectedId = null 
        //this.$refs.dialogCSFx.hide()
      }
      this.$forceUpdate()
    },
    'modalForm.processing': function (newVal, oldVal) {
      this.$forceUpdate()
    }
  },
  methods: {
    doShow () {
      this.modalForm.show = true
    },
    doHide () {
      this.modalForm.show = false
    },
    setShownDialog () {
      this.$cannaDebug('-- coloniasForm -- set Shown Dialog')
      // ---------------------
      setTimeout(() => {
        this.onReset()
        // acciones para el  formulario 
        this.initFormFlag = true
        this.$forceUpdate()
      }, 200)
    },
    doCloseFinishDialog () { 
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- coloniasForm -- doClose Finish Dialog')
      this.$emit('doCloseDialog', { newState: false, finish:true })
      this.modalForm.show = false
    },
    doCloseDialog (abortProc) {
      this.initFormFlag = false
      this.$cannaDebug('-- coloniasForm -- do Close Dialog')
      this.$emit('doCloseDialog', { newState: false, abort: abortProc, selItem:null })
      //this.modalForm.datesErrors.show = false
      this.modalForm.show = false
    },
    onReset () {
      this.selectedItem = null 
      this.selectedId = null 
      this.$forceUpdate()
    },
    // ---------------
    doSelectColonia: function( coloniaRecord ){
      if (this.selectedId=== coloniaRecord.id){
        this.selectedId = null
      } else { 
        this.selectedId = coloniaRecord.id
      }
      this.$forceUpdate()
    },
    // ---------------
    onSubmit: async function () {
      this.$cannaDebug('-- coloniasForm -- onSubmit')
      if (this.selectedId === null && this.searchRecords.length>0 ) { // Necesita un item 
        return false
      }
      // ------------ Continuamos 
      var test = 11
      var indexU = this.searchRecords.findIndex(item => item.id=== this.selectedId ) // Debe de existir 
      var itemUse = JSON.parse(JSON.stringify(this.searchRecords[indexU]))
      this.$emit('doConfirmSelection', { newState: false, abort: false, selItem:itemUse })
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- coloniasForm -- beforeCreate')
    this.initFormFlag = false
  },
  created () {
    this.$cannaDebug('-- coloniasForm -- created')
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- coloniasForm --  beforeMount')
  },
  mounted () {
    this.$cannaDebug('-- coloniasForm -- mounted')
    //LLENAR los puestos Valido
    //this.initFormFlag = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- coloniasForm -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- coloniasForm -- updated')
  },
  activated () {
    // this.$cannaDebug('-- coloniasForm -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- coloniasForm -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- coloniasForm -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- coloniasForm -- destroyed')
  }
}
</script>
