<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Ingresa los datos de tu dirección actual y algún número telefónico extra para poder contactarte
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        Es posible que necesitemos comunicarnos contigo para darte información de nuestras vacantes de trabajo,
        si no te localizamos en tu número celular intentaremos llamar a los teléfonos que registres aquí.
      </div>
    </div>

    <ValidationObserver ref="formstepPiE" tag="div"  class="ppi_contentZone spacerRowTop48" v-slot="{ handleSubmit, valid, passed }">

      <q-form 
        ref="myFormPie"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPieReset"
        class="formDetailZone "
      >

        <div class="dataSection_container spacerRowTop48" style="border-bottom: 1px solid #DCDBE1;">
          <div class="datasectionItem dataSection_separadorx" style="margin-left: 0px; padding-bottom: 0px;">

            <div class="row q-gutter-md" :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:740px; margin:0px;'">
                <!-- Campo de Codigo Postal -->
                <div class="col-12 col-sm-12 col-md-2"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 140px !important; margin-left:0px;'"
                >
                  <ValidationProvider 
                    tag="div" ref="fldx_cp" name="codigopostal" :rules=" { required: true, min:5, max:5 }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          Código postal
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        filled
                        name="codigopostal"
                        v-model="frmData.codigopostal"
                        maxlength="5"
                        :mask="'#####'"
                        unmasked-value
                        hint=""
                        :dense="true"
                        :label="(frmData.codigopostal==='' || frmData.codigopostal===null)?'C.P.':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errores.codigo_postal : ''"
                        @blur="pie_codigoPostalCheckData"
                      >
                      <!-- 
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('codigopostal','Código postal').replace('El campo','') : ''"
                       -->
                        <template v-slot:append>
                          <q-icon v-if="frmData.codigopostal !== null" class="cursor-pointer" name="clear" @click.stop="pie_resetBasicCPxData"/>
                        </template>
                      </q-input>

                  </ValidationProvider>

                </div>

                <!-- Campo de Entidad -->
                <div class="col-12 col-sm-12 col-md-5"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 290px !important;'"
                >
                  <ValidationProvider 
                    tag="div" ref="fldx_entidad" name="entidad" :rules=" { required: true, max:50 }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          Entidad federativa
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        :disable="true"
                        filled
                        name="entidad"
                        v-model="frmData.entidad"
                        maxlength="50"
                        hint=""
                        :dense="true"
                        :label="(frmData.entidad==='' || frmData.entidad===null)?'Llenado automático':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('entidad','Entidad federativa').replace('El campo','') : ''"
                      >
                      </q-input>

                  </ValidationProvider>

                </div>

                <!-- Campo de alcaldia -->
                <div class="col-12 col-sm-12 col-md-5"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 280px !important;'"
                >
                  <ValidationProvider 
                    tag="div" ref="fldx_alcaldia" name="alcaldia" :rules=" { required: true, max:50 }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          Alcaldía o Municipio
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        :disable="true"
                        filled
                        name="alcaldia"
                        v-model="frmData.alcaldia"
                        maxlength="50"
                        hint=""
                        :dense="true"
                        :label="(frmData.alcaldia==='' || frmData.alcaldia===null)?'Llenado automático':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('alcaldia','Alcaldía o Municipio').replace('El campo','') : ''"
                      >
                      </q-input>

                  </ValidationProvider>

                </div>
            </div>

            <div class="row q-gutter-md" :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:740px; margin:0px;margin-top: 16px;'">
              <!-- Campo de Ciudad -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:48%; margin-left:0px;'"
                >
                  <ValidationProvider 
                    tag="div" ref="fldx_ciudad" name="ciudad" :rules=" { required: true, max:50 }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          Ciudad
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        :disable="true"
                        filled
                        name="ciudad"
                        v-model="frmData.ciudad"
                        maxlength="50"
                        hint=""
                        :dense="true"
                        :label="(frmData.ciudad==='' || frmData.ciudad===null)?'Llenado automático':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('ciudad','Ciudad').replace('El campo','') : ''"
                      >
                      </q-input>

                  </ValidationProvider>
                </div>

              <!-- Campo de Colonia -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:48%;'"
                >
                  <!--Select de colonia -->

                  <ValidationProvider 
                    tag="div"
                    ref="fldx_grado" name="colonia" :rules=" { required: true }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                    v-show="(coloniaSearch===false)"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >Colonia</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-select
                        name="colonia"
                        filled
                        :dense="true"
                        v-model="frmData.colonia"
                        :label="(frmData.colonia ==='' || frmData.colonia === null) ? 'Elige una opción' : ''"
                        :options="colonia_Cat"
                        option-value="d_asenta"
                        option-label="d_asenta"
                        emit-value
                        map-options
                        :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? errors[0].replace('colonia','Colonia').replace('El campo','') : '' "
                      >
                        <!--  option-value="id" -->
                        <template v-slot:append>
                          <q-icon v-if="frmData.colonia !== null" class="cursor-pointer" name="clear" @click.stop="frmData.colonia = null"/>
                        </template>
                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                            <q-item-section>
                              <q-item-label>{{scope.opt.d_asenta}}</q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>

                      </q-select>

                      <div class="link_action">
                        ¿No aparece tu colonia? <span class="link_actionText action_item" @click="pie_StartColoniaSearch">Búscala por nombre</span>
                      </div>
                  </ValidationProvider>


                  <!-- Buscador -->
                  <div class="fldx_coloniaSearcherxx" v-show="(coloniaSearch===true)">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect">Colonia</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>

                      <q-input
                        filled
                        name="buscador"
                        v-model="coloniaSearchTxt"
                        maxlength="50"
                        hint=""
                        class="custom_SearchInput inputColoniaSearch"
                        :dense="true"
                        :label="(coloniaSearchTxt==='' || coloniaSearchTxt==null)?'Escribe el nombre de tu colonia':''"
                      >
                        <template v-slot:prepend>
                          <q-icon name="search" />
                        </template>

                        <template v-slot:append>
                          <q-icon v-if="coloniaSearchTxt !== ''" name="clear" @click.stop="pie_clearColoniaSearch" class="cursor-pointer" />
                        </template>

                        <template v-slot:after>
                          <q-btn 
                            type="button" class="btnColoniaDoSearch" 
                            unelevated color="app_mainBtn" 
                            label="Buscar" 
                            no-caps @click.stop="pie_MakeSearch"
                          />
                        </template>

                      </q-input>

                      <div class="link_action">
                        Quiero <span class="link_actionText action_item" @click="pie_returnColoniaSelect">volver al catálogo de colonias</span>
                      </div>
                  </div>

                </div>

            </div>

            <div class="row q-gutter-md" :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-top:4px; margin-left: 0px;' : 'max-width:740px; margin:0px; margin-top: 16px;'">
              <!-- Campo de Calle --> 
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:48%; margin-left:0px;'"
                >
                  <ValidationProvider 
                    tag="div" ref="fldx_ciudad" name="calle" 
                    :rules=" { 
                      required: true, 
                      emojiFree:true, 
                      cannaTextoParaDireccion:{min:5, max:50},
                      min:5, max:50 }" 
                    :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          Calle 
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        filled
                        name="calle"
                        v-model="frmData.calle"
                        maxlength="80"
                        hint=""
                        :dense="true"
                        :label="(frmData.calle==='' || frmData.calle===null)?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  
                         errors[0].replace('calle','La calle').replace('El campo','').replace('es obligatorio','es un valor obligatorio') : ''"
                      >
                      <!-- 
                        :error-message="(errors[0] !==undefined) ?  errores.calle : ''"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('calle','Calle').replace('El campo','') : ''"
                       -->
                        <template v-slot:append>
                          <q-icon v-if="frmData.calle !== null" class="cursor-pointer" name="clear" @click.stop="frmData.calle = null"/>
                        </template>
                      </q-input>

                  </ValidationProvider>
                </div>

              <!-- Campos de exterior e interior --> 
                <div class="col-12 col-sm-12 col-md-6 row q-gutter-md justify-between" 
                  style="margin-top: 0px;"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:48%;'"
                >
                  <div class="col-6 col-sm-6 col-md-6"
                    :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px; max-width:48%;' : 'max-width:48%; margin-left:0px;'"
                  >
                    <ValidationProvider 
                      tag="div" ref="fldx_ciudad" name="exterior" 
                      :rules=" { 
                        required: true, 
                        emojiFree:true,
                        cannaTextoParaDireccion:{min:1, max:20},
                        min:1, max:20 
                      }" 
                      :immediate="false" 
                      v-slot="{ errors, invalid, validated  }"
                    >
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                            Número exterior 
                          </div>
                          <div class="requiredLabel text-right" >&nbsp;</div>
                        </div>
                        <q-input
                          filled
                          name="exterior"
                          v-model="frmData.exterior"
                          maxlength="20"
                          hint=""
                          :dense="true"
                          :label="(frmData.exterior==='' || frmData.exterior===null)?'':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errores.exterior : ''"
                        >
                        <!-- 
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('exterior','Número exterior').replace('El campo','') : ''"
                         -->
                          <template v-slot:append>
                            <q-icon v-if="frmData.exterior !== null" class="cursor-pointer" name="clear" @click.stop="frmData.exterior = null"/>
                          </template>
                        </q-input>
                    </ValidationProvider>
                  
                  </div>

                  <div class="col-6 col-sm-6 col-md-6"
                    :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px; max-width:48%;' : 'max-width:48%; margin-left:0px;'"
                  >
                    <ValidationProvider 
                      tag="div" ref="fldx_ciudad" name="interior" 
                      :rules=" { 
                        required: false, 
                        emojiFree:true, 
                        cannaTextoParaDireccion:{min:1, max:20},
                        min:1, max:20 
                      }" 
                      :immediate="false" 
                      v-slot="{ errors, invalid, validated  }"
                    >
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                            Número interior 
                          </div>
                          <div class="requiredLabel text-right" >&nbsp;</div>
                        </div>
                        <q-input
                          filled
                          name="interior"
                          v-model="frmData.interior"
                          maxlength="20"
                          hint=""
                          :dense="true"
                          :label="(frmData.interior==='' || frmData.interior===null)?'':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errores.interior : ''"
                        >
                        <!-- 
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('interior','Número interior').replace('El campo','') : ''"
                        -->
                          <template v-slot:append>
                            <q-icon v-if="frmData.interior !== null" class="cursor-pointer" name="clear" @click.stop="frmData.interior = null"/>
                          </template>
                        </q-input>
                    </ValidationProvider>

                  </div>

                </div>

            </div>

            <div class="row q-gutter-md" :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:740px; margin:0px;margin-top: 16px;'">
              <!-- Campo entre calles --> 
                <div class="col-12 col-sm-12 col-md-12"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:98%; margin-left:0px;'"
                >
                  <ValidationProvider 
                    tag="div" ref="fldx_entrecalles" name="entrecalles" 
                    :rules=" { 
                      required: false, 
                      emojiFree:true, 
                      cannaTextoParaDireccion:{min:5, max:100},
                      min:5, max:100 
                    }" 
                    :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          Entre calles
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        filled
                        name="entrecalles"
                        v-model="frmData.entrecalles"
                        maxlength="50"
                        hint=""
                        :dense="true"
                        :label="(frmData.entrecalles==='' || frmData.entrecalles===null)?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errores.entre_calles : ''"
                      >
                      <!-- 
                         :error-message="(errors[0] !==undefined) ?  errors[0].replace('entrecalles','Entre calles').replace('El campo','') : ''"
                      -->
                        <template v-slot:append>
                          <q-icon v-if="frmData.entrecalles !== null" class="cursor-pointer" name="clear" @click.stop="frmData.entrecalles = null"/>
                        </template>
                      </q-input>

                  </ValidationProvider>
                </div>

            </div>

          </div>
        </div>

        <!-- ZONA de telefonos --> 
        <div class="dataSection_container spacerRowTop24">
          <div class="datasectionItem dataSection_separadorx" style="margin-left: 0px;">
            <div class="row" :style="(is_Mobile === true || $q.screen.lt.md) ? '' : 'max-width:650px'">

                <div class="col-12 col-sm-12 col-md-12"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:98%; margin-left:0px;'"
                >
                  <ValidationProvider
                    :rules=" { 
                      required:true , 
                      cannaPhones:true, 
                      emojiFree:true,
                      min:10,
                      max:10,
                      cannaQuest_NotSameAsMainPhone  : {mainPhone: mainCuentaPhone }
                    } " 
                    :immediate="true" ref="fldx_telefonorecados" name="tel_recados" 
                    v-slot="{ errors, invalid, validated  }"
                    tag="div" class="row justify-between"
                  >
                    <input type="hidden" name="tel_recados"  v-model="frmData.telefono_recados">

                    <div class="col-12 row  q-gutter-md" style="margin-top: 0px !important; max-width: 390px;">

                      <div class="col-12" style="display: flex;justify-content: space-between; max-width: calc(100% - 16px);margin-top: 8px;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >Teléfono de recados </div>
                        <div class="requiredLabel text-right" >Obligatorio</div>
                      </div>

                      <!-- LADA -->
                      <div class="col" style="max-width: 90px; margin-top:0px;">
                        <q-input
                          filled
                          disable
                          readonly
                          name="lada"
                          v-model="frmData.lada"
                          hint=""
                          :dense="true"
                          :label="(frmData.lada==='')? '':''"
                          :disabled="processingData"
                        />
                      </div>
                      <!-- telefono -->
                      <div class="col" style="margin-top:0px;">
                        <q-input
                          filled
                          name="telefono"
                          v-model="frmData.telefono_recados"
                          hint=""
                          :dense="true"
                          :mask="'(##) #### ####'"
                          unmasked-value
                          :label="(frmData.telefono_recados==='' ||  frmData.telefono_recados === null)? 'Número a 10 dígitos':''"
                          :error="invalid && validated"
                          @blur="pie_onBlurCampoTel('telefono_recados', $event)"
                        >
                          <template v-slot:append>
                            <q-icon v-if="frmData.telefono_recados !== null" class="cursor-pointer" name="clear" @click.stop="frmData.telefono_recados = null"/>
                          </template>
                        </q-input>
                        <!--
                          :error-message="(errors[0] !==undefined) ? errores.phone : ''"
                        -->
                      </div>
                    </div>

                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="col-12" style="margin-top: -16px;">
                        <div class="q-field--error q-field__bottom" 
                          style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center;"
                        >
                        {{ 
                          (errors[0].indexOf('mismo que tu número celular')>=0) ? 
                            errors[0].replace('tel_recados', 'teléfono de recados').replace('El campo','')  : 
                              (errors[0].indexOf('El número no existe')>=0) ?  errors[0].replace('tel_recados', 'teléfono de recados').replace('El campo','')  : 
                                errores.phone
                        }}
                        <!-- {{ errores.phone ||  }} errors[0].replace('tel_recados', 'Telefono de recados').replace('El campo','') -->
                        </div>
                      </div>
                    </template>

                  </ValidationProvider>

                </div>

                <div class="col-12 col-sm-12 col-md-12 spacerRowTop24"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width:98%; margin-left:0px;'"
                >
                  <ValidationProvider
                    :rules=" { 
                      required:false, 
                      cannaPhones:true,
                      emojiFree:true,
                      min:10,
                      max:10,
                      cannaQuest_NotSameAsMainPhone  : {mainPhone: mainCuentaPhone },
                      cannaQuest_NotSameAsRecadosPhone: {mainPhone: frmData.telefono_recados },
                    } " 
                    :immediate="true" ref="fldx_telefonocasa" name="tel_casa" 
                    v-slot="{ errors, invalid, validated  }"
                    tag="div" class="row justify-between"
                  >
                    <input type="hidden" name="tel_casa"  v-model="frmData.telefono_casa">
                    <div class="col-12 row  q-gutter-md" style="margin-top: 0px !important; max-width: 390px;">

                      <div class="col-12" style="display: flex;justify-content: space-between; max-width: calc(100% - 16px);margin-top: 8px;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >Teléfono de casa </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>

                      <!-- LADA -->
                      <div class="col" style="max-width: 90px; margin-top:0px;">
                        <q-input
                          filled
                          disable
                          readonly
                          name="lada_b"
                          v-model="frmData.lada"
                          hint=""
                          :dense="true"
                          :label="(frmData.lada==='')? '':''"
                          :disabled="processingData"
                        />
                      </div>
                      <!-- telefono -->
                      <div class="col" style="margin-top:0px;">
                        <q-input
                          filled
                          name="telefono_casa"
                          v-model="frmData.telefono_casa"
                          hint=""
                          :dense="true"
                          :mask="'(##) #### ####'"
                          unmasked-value
                          :label="(frmData.telefono_casa==='' ||  frmData.telefono_casa === null)? 'Número a 10 dígitos':''"
                          :error="invalid && validated"
                          @blur="pie_onBlurCampoTel('telefono_casa', $event)"
                        >
                          <template v-slot:append>
                            <q-icon v-if="frmData.telefono_casa !== null && frmData.telefono_casa !== '' " class="cursor-pointer" 
                              name="clear" @click.stop="frmData.telefono_casa = null"
                            />
                          </template>
                        </q-input>
                        <!--
                          :error-message="(errors[0] !==undefined) ? errores.phone : ''"
                        -->
                      </div>

                    </div>
                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="col-12" style="margin-top: -16px;">
                        <div class="q-field--error q-field__bottom" 
                          style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center;"
                        >
                        {{ 
                          (errors[0].indexOf('mismo que tu número celular')>=0) ? 
                            errors[0].replace('tel_casa', 'teléfono de casa').replace('El campo','') : 
                              (errors[0].indexOf('mismo que el número de recados')>=0) ? 
                                errors[0].replace('tel_casa', 'teléfono de casa').replace('El campo','') : 
                                  (errors[0].indexOf('El número no existe')>=0) ?  errors[0].replace('tel_casa', 'teléfono de casa').replace('El campo','')  : 
                                    errores.phone
                        }}
                        <!-- 
                          {{ errores.phone }}
                          errors[0].replace('tel_recados', 'Telefono de recados').replace('El campo','') 
                        -->
                        </div>
                      </div>
                    </template>

                  </ValidationProvider>

                </div>

            </div>
          </div>
        </div>

        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>
    
    <!-- MOdal para buscador de colonias --> 
    <frmColoniaSearch
      ref="frmModalColoniaSearch"
      :actionForm="'results'"  
      :searchTextColonia="coloniaSearchTxt"
      :searchRecords="coloniaSearch_Data"
      v-on:doCloseDialog="pie_doCloseColoniaSearch"
      v-on:doConfirmSelection="pie_doConfirmColoniaSelection"
    ></frmColoniaSearch>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from './../../../../boot//axios' // APP code 
import logOut_Mixin from './../../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

const tplpij_Data = {
  codigopostal:null, 
  entidad: null, 
  alcaldia:null, 
  ciudad:null, 
  colonia:null, 
  coloniaData: [],
  calle: null, 
  exterior: null,
  interior: null,
  entrecalles: null,
  telefono_recados: null,
  telefono_casa: null,
  lada: 'MX (+52)'
}

export default {
  name: 'psi_screen_direccion',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    questionario_Mixin
  ],
  components:{
    frmColoniaSearch: require('components/forms/questionario/coloniasForm.vue').default
  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      z_allowColonias:[],
      frm_coloniaModalShow: true, 
      coloniaSearch:false, 
      coloniaSearchTxt:'',
      coloniaSearch_Data:[],
      colonia_CatBase:[],
      colonia_Cat:[],
      profileRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: JSON.parse(JSON.stringify(tplpij_Data)),
      frmData: JSON.parse(JSON.stringify(tplpij_Data)),
      errores:{
        codigo_postal: 'Este código postal no es válido',
        calle: 'Solo puedes ingresar entre 5 y 50 caracteres pueden ser letras, números, y los signos: . , -', //'Por favor, escribe un dato que contenga entre 5 y 50 caracteres.', 
        exterior: 'Solo puedes ingresar entre 1 y 20 caracteres pueden ser letras, números, y los signos: . , -', // 'Por favor, escribe un dato que contenga entre 1 y 20 caracteres.', 
        interior: 'Solo puedes ingresar entre 1 y 20 caracteres pueden ser letras, números, y los signos: . , -', // 'Por favor, escribe un dato que contenga entre 1 y 20 caracteres.', 
        entre_calles:'Solo puedes ingresar entre 5 y 100 caracteres pueden ser letras, números, y los signos: . , -', // 'Por favor, escribe un dato que contenga entre 5 y 100 caracteres.', 
        phone_api:'',
        phoneCasa_api:'', 
        phone: 'El número ingresado no es válido. Por favor, revisa e inténtalo de nuevo. ',
        phoneCasa: 'El número ingresado no es válido. Por favor, revisa e inténtalo de nuevo. '
      },
      frmMainRefs:{
        formstepPiE: [
          {fieldNameAPi: 'codigo_postal', fieldNameForm: 'codigopostal' },
          {fieldNameAPi: 'entidad_federativa', fieldNameForm: 'entidad' },
          {fieldNameAPi: 'alcaldia_municipio', fieldNameForm: 'alcaldia' },
          {fieldNameAPi: 'ciudad', fieldNameForm: 'ciudad'},
          {fieldNameAPi: 'colonia', fieldNameForm: 'colonia' },
          {fieldNameAPi: 'calle', fieldNameForm: 'calle' },
          {fieldNameAPi: 'numero_exterior', fieldNameForm: 'exterior' },
          {fieldNameAPi: 'numero_interior', fieldNameForm: 'interior' },
          {fieldNameAPi: 'entre_calles', fieldNameForm: 'entrecalles' },
          {fieldNameAPi: 'telefono_recados', fieldNameForm: 'tel_recados' },
          {fieldNameAPi: 'telefono_casa', fieldNameForm: 'tel_casa' }
        ]
      },
    }
  },
  computed: {
    mainCuentaPhone () { 
      return this.getProfileData.phone
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_direccion -- test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/trabajo-d'
      this.$cannaDebug('-- psiPage_direccion -- do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    pie_clearColoniaSearch () { 
      this.$cannaDebug('-- psiPage_direccion -- clear Colonia Search --- ')
      this.coloniaSearchTxt = ''
      this.$forceUpdate()
    },
    pie_returnColoniaSelect (){
      this.$cannaDebug('-- psiPage_direccion -- return Colonia Select --- ')
      this.coloniaSearchTxt = ''
      this.coloniaSearch = false 
      this.$forceUpdate()
    },
    pie_StartColoniaSearch () { 
      this.$cannaDebug('-- psiPage_direccion -- Start Colonia Search --- ')
      this.coloniaSearchTxt = ''
      this.coloniaSearch = true
      this.$forceUpdate()
    },
    pie_resetBasicCPxData(){
        this.frmData.codigopostal = null
        this.frmData.entidad = null
        this.frmData.alcaldia = null
        this.frmData.ciudad = null
        this.frmData.colonia = null
        this.colonia_Cat = []
        this.$forceUpdate()
    },
    async pie_codigoPostalCheckData (){ 
      this.$cannaDebug('-- psiPage_direccion -- pie_ codigo Postal CheckData --- ')
      var test=11
      var fieldResp = null 
      await this.$refs.fldx_cp.validate().then((response) => { 
        fieldResp = response
      })
      test=22 
      if (fieldResp.valid===false) {
        this.pie_resetBasicCPxData()
        return false
      } else { // Buscamos el CP
        this.showLoadx(true, 1)

        var mcModel = this.getModelRequest('postalCode')
        var reqRes = { action: [] }
        var reqErr = { action: 0 }
        // Hasta aqui estamos bien y obtenemos los datos de profile
        var myFormConfig = this.createAxiosConfig ('get', mcModel, { d_codigo: '' + this.frmData.codigopostal, 'per-page': 'all'}, true, null)
        await this.doRequestByPromise(this.$apiV2, 'postalCode', reqRes.action, myFormConfig).then((resx) => {
          // console.log ('Action -- Carga de Clientes -- GET ejecutado')
          var tmpresponseData = reqRes.action[reqRes.action.length - 1]
          if (tmpresponseData.type !== 'success') {
            reqErr.action += 1
          }
        })
        if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
          test = 55
          this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
          this.showLoadx(false, 1)
          this.pie_resetBasicCPxData()
          return false
        }
        var cpData = reqRes.action[0].data.data

        if (cpData.length<=0){ 
          this.showLoadx(false, 1)
          var myMsg = 'El código postal que ingresaste no existe.  Por favor, revisa e inténtalo de nuevo.'
          this.$q.notify({ type: 'warning', position: 'top', message: '' + myMsg}) // negative
          this.pie_resetBasicCPxData()
          return false
        }
        // Si hay registros (se toma el primero)
        var tmpRecord = JSON.parse(JSON.stringify(cpData[0]))
        this.frmData.entidad = tmpRecord.d_estado
        this.frmData.alcaldia = tmpRecord.D_mnpio
        this.frmData.ciudad = (tmpRecord.d_ciudad === '' || (''+ tmpRecord.d_ciudad).trim()===''  ) ? '-' : tmpRecord.d_ciudad
        this.frmData.colonia = tmpRecord.d_asenta
        this.colonia_Cat = cpData
        this.showLoadx(false, 1)
        this.$forceUpdate() 
        return true 
      }
    },
    async pie_MakeSearch(){ 
      this.$cannaDebug('-- psiPage_direccion -- pie _ do Close Colonia Search --- ')
      var info = this.coloniaSearchTxt 
      if (info.length<4){ 
        return
      }
      var mcModel = this.getModelRequest('postalCode')
      var reqRes = { action: [] }
      var reqErr = { action: 0 }

      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, { d_asenta: '' + this.coloniaSearchTxt  , 'per-page': 'all'}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'postalCode', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.coloniaSearch_Data = []
        return false 
      }
      var cpData = reqRes.action[0].data.data
      this.coloniaSearch_Data = cpData
      this.$forceUpdate() 
      this.showLoadx(false, 1)
      setTimeout(() => {
        // this.frmBaseModalShow = true
        this.$refs.frmModalColoniaSearch.doShow()
      }, 200)
      return true
    },
    // Modal de colonia search 
    pie_doCloseColoniaSearch (payload ){ 
      this.$cannaDebug('-- psiPage_direccion -- pie _ do Close Colonia Search --- ')
      //payload ==  this.$emit('doCloseDialog', { newState: false, abort: abortProc, selItem:null })
      if (payload.hasOwnProperty('abort')){
        if (payload.abort===true){
          this.$refs.frmModalColoniaSearch.doHide()
        }
      }
    },
    async pie_doConfirmColoniaSelection (payload ){
      this.$cannaDebug('-- psiPage_direccion -- pie _ do Confirm Colonia Selection --- ')
      var test = 11 
      //payload ==  this.$emit('doConfirmSelection', { newState: false, abort: false, selItem:itemUse })
      if (payload.hasOwnProperty('abort')){
        if (payload.abort===true){
          return false
        }
      }
      var myPayload = JSON.parse(JSON.stringify(payload))
      this.$refs.frmModalColoniaSearch.doHide()
      this.showLoadx(true, 1)
      var itemRecord = myPayload.selItem
      var mcModel = this.getModelRequest('postalCode')
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, { d_codigo: '' + itemRecord.d_codigo  , 'per-page': 'all'}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'postalCode', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        this.showLoadx(false, 1)
        this.pie_resetBasicCPxData()
        return false
      }
      var cpData = reqRes.action[0].data.data // Los datos para llenar el select 
      this.frmData.codigopostal = itemRecord.d_codigo
      this.frmData.entidad = itemRecord.d_estado
      this.frmData.alcaldia = itemRecord.D_mnpio
      this.frmData.ciudad = (itemRecord.d_ciudad === '' || (''+ itemRecord.d_ciudad).trim()===''  ) ? '-' : itemRecord.d_ciudad
      this.frmData.colonia = itemRecord.d_asenta
      this.colonia_Cat = cpData
      this.showLoadx(false, 1)

      // Escondemos la busqueda 
      this.pie_returnColoniaSelect() 

      this.$forceUpdate() 
      return true
    },
    onStepFormPieReset () {

    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_direccion -- do Submit Data --- ')
      var test = 11 , tmpArrZ = null, tmpArrW = null 
      var toAdd = [], toRemove = [], toPatch = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1)

      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 
      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 ) { // Que existan valores...!
        var changesObj= {}
        // Revisamos los datos del registro perfil (descripcion, requiere check , sueldo)
        var fieldsG = [
          ['codigopostal','codigo_postal'], ['entidad','entidad_federativa'], 
          ['alcaldia','alcaldia_municipio'], ['ciudad','ciudad'],
          ['colonia','colonia'], ['calle','calle'], 
          ['exterior','numero_exterior'], ['interior','numero_interior'],
          ['entrecalles','entre_calles'], ['telefono_recados','telefono_recados'], ['telefono_casa','telefono_casa']
        ]
        var genConfigPatch = null
        var tmpConfig = {}
        for (const campoG of fieldsG) {
          var campoUse = campoG[0]
          var campoApi = campoG[1]
          if (testA._[campoUse].status === 'MODIFIED') {
            var nameGfx = ''
            var valorGfx = null
            if (campoUse==='interior' || campoUse==='entrecalles'){
              valorGfx = (testA._[campoUse].current === null || testA._[campoUse].current === '')  ? '' : testA._[campoUse].current
              tmpConfig[campoApi] = valorGfx
            } else if (campoUse==='telefono_casa' ){ 
              valorGfx = (testA._[campoUse].current === null || testA._[campoUse].current === '')  ? '' : '+52' + testA._[campoUse].current
              tmpConfig[campoApi] = valorGfx
            } else if (campoUse==='telefono_recados' ){ 
              valorGfx = '+52' + testA._[campoUse].current
              tmpConfig[campoApi] = valorGfx
            } else { 
              valorGfx = testA._[campoUse].current
              tmpConfig[campoApi] = valorGfx
            }
          }
        }
        if (Object.keys(tmpConfig).length > 0) {
          genConfigPatch = tmpConfig
        }
        // -----------
        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [], profilex:[] }
        var reqErr = { action: 0, profilex:0 }
        var mcModel = this.getModelRequest('profileDireccion')
        var mpxModel = this.getModelRequest('userProfile')
        // ----------------------
        var myProfileEditConfig = this.createAxiosConfig ('patch', mcModel, { useData:false, data: genConfigPatch } , true, null)
        await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.action, myProfileEditConfig).then((resx) => {
          // console.log ('Action -- Carga de Clientes -- GET ejecutado')
          var tmpresponseData = reqRes.action[reqRes.action.length - 1]
          if (tmpresponseData.type !== 'success') {
            reqErr.action += 1
          }
        })
        if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
          test = 44
          this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepPiE, 'formstepPiE',  this.frmMainRefs.formstepPiE , 'formstepPiE')
          this.showLoadx(false, 1)
          this.$forceUpdate()
          return false 
        }
        // Hasta aqui todo bien 
        await this._waitRequestProc(2000)
        // Necesitamos actualizar los datos de profile de la persona...! 
        var myProfileDataConfig = this.createAxiosConfig ('get', mpxModel, { } , true, null)
        await this.doRequestByPromise(this.$apiV2, 'profile', reqRes.profilex, myProfileDataConfig).then((resx) => {
          // console.log ('Action -- Carga de Clientes -- GET ejecutado')
          var tmpresponseData = reqRes.profilex[reqRes.profilex.length - 1]
          if (tmpresponseData.type !== 'success') {
            reqErr.profilex += 1
          }
        })
        if (reqErr.profilex > 0) { // Si hay error de server, session o algo redireccionamos..!
          test = 44
          this.ex_handleErrorRequest (reqRes.profilex[0], reqErr.profilex)
          this.showLoadx(false, 1)
          this.$forceUpdate()
          return false 
        }
        var newProfileData = reqRes.profilex[0].data.data // Los datos actualizados del profile
        var newConfigVals = {}
        test = 100
        for (const campoG of fieldsG) {
          var campoApi = campoG[1]
          newConfigVals[campoApi] = newProfileData[campoApi]
        }

        test = 200
        this.$store.commit('myapp/setAppUserProfileData', newConfigVals)
        this.$store.commit('myapp/setAppUserProfileData', {status:newProfileData.status })

        this.$forceUpdate()
        await this._waitRequestProc(2000)
        // Terminamos
        // ----------
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData))
        // alert('prueba avance')
        allowNextPage = true

      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }
      
      this.$forceUpdate()
      await this._waitRequestProc(500)

      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if ( this.currProfile_status.status< this.zstatUser.DIRECCION ){ //if (this.currProfile_status < 17){
        await this.hpb_updateProfileStatus( this.zstatUser.DIRECCION ).then(respx=>{
          retUPS = respx
        })
      }
      // ----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/direccion-b'
        this.$router.push(newRuta)
      }
      return true
    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var postalModel = this.getModelRequest('postalCode')
      var reqRes = { action: [], colonias: [], profile: [] }
      var reqErr = { action: 0, colonias: 0, profile: 0 }

      // Hasta aqui estamos bien y obtenemos los datos de profile
      var baseData = JSON.parse(JSON.stringify(tplpij_Data))
      var pxData = JSON.parse(JSON.stringify(this.getProfileData))

      if (pxData.codigo_postal!==undefined && pxData.codigo_postal!==null && pxData.codigo_postal!=='' ){

        baseData.codigopostal = (pxData.codigo_postal.length!==5) ? null : pxData.codigo_postal
        baseData.entidad = (pxData.entidad_federativa === null || pxData.entidad_federativa==='') ? null : pxData.entidad_federativa
        baseData.alcaldia = (pxData.alcaldia_municipio === null || pxData.alcaldia_municipio==='') ? null : pxData.alcaldia_municipio
        baseData.ciudad = (pxData.ciudad === null || pxData.ciudad==='') ? null : pxData.ciudad
        baseData.colonia = (pxData.colonia === null || pxData.colonia==='') ? null : pxData.colonia
        baseData.calle = (pxData.calle === null || pxData.calle==='') ? null : pxData.calle
        baseData.exterior = (pxData.numero_exterior === null || pxData.numero_exterior==='') ? null : pxData.numero_exterior
        baseData.interior = (pxData.numero_interior === null || pxData.numero_interior==='') ? null : pxData.numero_interior
        baseData.entrecalles = (pxData.entre_calles === null || pxData.entre_calles==='') ? null : pxData.entre_calles
        await this._waitRequestProc(200)
        //Hacemos el request para obtener lista de colonia
        if (pxData.codigo_postal.length===5){
          var myFormConfig = this.createAxiosConfig ('get', postalModel, { d_codigo: '' + baseData.codigopostal  , 'per-page': 'all'}, true, null)
          await this.doRequestByPromise(this.$apiV2, 'postalCode', reqRes.action, myFormConfig).then((resx) => {
            // console.log ('Action -- Carga de Clientes -- GET ejecutado')
            var tmpresponseData = reqRes.action[reqRes.action.length - 1]
            if (tmpresponseData.type !== 'success') {
              reqErr.action += 1
            }
          })
          if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
            test = 55
            this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
            this.colonia_Cat = []
          } else { 
            var cpData = reqRes.action[0].data.data // Los datos para llenar el select 
            this.colonia_Cat = cpData
          }
        } else { 
          this.colonia_Cat = []
        }
      } else { // Se limpian los datos del combo y va todo en blanco 
        this.colonia_Cat = []
      }
      //-- Telefonos 
      baseData.telefono_casa = (pxData.telefono_casa === null || pxData.telefono_casa==='') ? null : pxData.telefono_casa.replace('+52','') 
      baseData.telefono_recados = (pxData.telefono_recados === null || pxData.telefono_recados==='') ? null : pxData.telefono_recados.replace('+52','') 

      this.frmDataOriginal = JSON.parse(JSON.stringify(baseData))
      this.frmData = JSON.parse(JSON.stringify(baseData))

      return true
    },
    //--------------------------
    //Utilerias para validar telefonos (MOVIL FIJO) 
    pie_onBlurCampoTel: function( fieldName , event ){ 
      this.$cannaDebug('-- accountIndexScreen -- pasoB onBlur Campo')
      if (fieldName === 'telefono_recados'){
        this.pie_validateByPhone( fieldName , 1)
      }
      if (fieldName === 'telefono_casa'){
        this.pie_validateByPhone( fieldName , 2)
      }
      this.$forceUpdate()
      // @blur="pie_onBlurCampoTel('telefono_recados', $event)"
      // @blur="pie_onBlurCampoTel('telefono_casa', $event)"
    },
    pie_validateByPhone: async function(fieldX, tipoPh){ 
      //tipoPh  1: que exista solamente  2: que sea FIJO 
      var test = 11
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      var myPhone = ''
      var myPhone = (fieldX === 'telefono_recados') ? ('+52' + this.frmData.telefono_recados).trim() : ('+52' + this.frmData.telefono_casa).trim()

      if (myPhone.length!==13){
        return true 
      }

      var mpModel = this.getModelRequest('telefonoCheck')
      var myTelCfg = this.createAxiosConfig ('get', mpModel, { numero: myPhone }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profilepersonales', reqRes.action, myTelCfg).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // no deberia haber errores nunca, solo es consulta 
        test = 44
        this.ex_handleErrorRequest(reqRes.action[0], reqErr.action)
        // this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepB, 'formstepB',  this.frmMainRefs.pasoB , 'pasoB')
        this.loading_Process = false
        this.$forceUpdate()
        return false 
      }
      var telData = reqRes.action[0].data.data
      if (telData.length<=0){
        // Mostrar error q no existe el telefono 
        var myObjectNoPhone = null 
        if (fieldX === 'telefono_recados') { 
          myObjectNoPhone = { tel_recados: 'El número no existe o no corresponde a un número telefónico. Por favor, revisa e inténtalo de nuevo.'}
        } else { 
          myObjectNoPhone = { tel_casa: 'El número no existe o no corresponde a un número telefónico. Por favor, revisa e inténtalo de nuevo.' }
        }
        this.$refs.formstepPiE.setErrors(myObjectNoPhone);
        this.$forceUpdate()
        return false

      } else {

        // checar si es FIJO si no manda error 
        var myREcord = telData[0]
        if (tipoPh===2) {  // Verificar que se a FIJO  solo aplica a casa 
          if (myREcord.tipo_red!=='FIJO'){
            var myObjectNoPhone = { 
              tel_casa: 'El número ingresado no corresponde a un número local y/o fijo. Por favor, revisa e inténtalo de nuevo.'
            }
            this.$refs.formstepPiE.setErrors(myObjectNoPhone);
            this.$forceUpdate()
            return false 
          }

        } else {
          return true 
        }
      }
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_direccion -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_direccion -- created')
    this.zloadingData = true 
    this.zinitScreen = false
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_direccion --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_direccion -- mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)

    await this._waitRequestProc(500)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    
    if (this.$refs.fldx_telefonorecados){
      this.$refs.fldx_telefonorecados.reset()
    }
    
    await this._waitRequestProc(500)
    this.$forceUpdate()

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_direccion -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_direccion -- updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_direccion -- activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)

      await this._waitRequestProc(500)
      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })

      if (this.$refs.fldx_telefonorecados){
        this.$refs.fldx_telefonorecados.reset()
      }

      await this._waitRequestProc(500)
      this.$forceUpdate()

      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_direccion -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_direccion -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_direccion -- destroyed')
  }
}
</script>
